<template>
  <div class="pa-5">
    <v-card
        class="mx-auto"
    >
      <v-card-title>
        <h2 v-t="'Texts.TextsManagement'"></h2>
        <v-spacer></v-spacer>
        <v-btn color='primary' @click="$router.push('Texts/new')">
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t('Texts.AddText') }}
        </v-btn>
      </v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th v-t="'General.Preview'" class='text-center'></th>
            <th v-t="'General.PublishingStatus'" class='text-center'></th>
            <th v-t="'Texts.TextName'" class='text-center'></th>
            <th v-t="'General.Actions'" class='text-center'></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for='text in texts' :key='text.id'>
            <td style="cursor: pointer" @click="showModal(text.id)">
              <div v-if="text.textPath">
                <object style="pointer-events: none; height: 200px;" :data="$axios.defaults.baseURL + '/downloadSvg?filename=' + text.textPath"></object>
                <v-dialog v-model="showImage[text.id]" width="500px">
                  <v-card>
                    <object style="height: 500px;" :data="$axios.defaults.baseURL + '/downloadSvg?filename=' + text.textPath"></object>
                  </v-card>
                </v-dialog></div>
            </td>
            <td>
              <div style="display: flex; justify-content: center;">
                <v-switch
                    @change="modifyText(text)"
                    v-model="text.published"
                ></v-switch>
              </div>
            </td>
            <td>{{ text.name }}</td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="black"
                      icon
                      dark
                      @click="configText(text)"
                  >
                    <v-icon>mdi-draw</v-icon>
                  </v-btn>
                </template>
                <span v-t="'General.EditDesign'"></span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="secondary"
                      icon
                      dark
                      @click="$router.push('Texts/' + text.id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span v-t="'General.Edit'"></span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="red"
                      icon
                      @click='deleteText(text)'
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span v-t="'General.Delete'"></span>
              </v-tooltip>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
  import Vue from 'vue'
  export default {
    name: 'Texts',
    data () {
      return {
        texts: [],
        showImage: []
      }
    },
    created () {
      this.getTexts()
    },
    methods: {
      showModal (textid) {
        Vue.set(this.showImage, textid, true)
      },
      configText (text) {
        this.$router.push('/TextCreator/' + text.id)
      },
      deleteText (text) {
        this.$store.dispatch('deleteText', text.id).then(() => {
          this.texts = this.$store.getters['GET_TEXTS']()
          this.$emit('notify', {
            color: 'green',
            text: this.$t('Messages.TextDeleted')
          })
        }).catch((err) => {
          this.$emit('notify', {
            color: 'red',
            text: this.$t('Messages.' + err.response.data)
          })
        })
      },
      getTexts () {
        this.$store.dispatch('getAllTexts').then(() => {
          this.texts = this.$store.getters['GET_TEXTS']()
        })
      },
      modifyText (text) {
        this.$store.dispatch('editText', text).catch((err) => {
          this.$emit('notify', {
            color: 'red',
            text: this.$t('Messages.' + err.response.data)
          })
        })
      }
    }
  }
</script>

<style scoped>

</style>
